import styled from "styled-components";

export const FieldTitle = styled.span`
  /* Reset */
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.text.secondary};
  font-size: 0.8rem;
  /* Montserrat semi-bold */
  font-weight: 600;
  line-height: 1.5;
`;
