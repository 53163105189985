import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/input/Button";
import Box from "../../components/layout/Box";
import Header from "../../components/typography/Header";
import Paragraph from "../../components/typography/Paragraph";
import BlobsBackground from "../../features/BlobsBackground";

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <BlobsBackground />
      <Box padding={[16, 2]} maxWidth={32}>
        <Header>{t("home.hero.header")}</Header>
        <Box padding={[2, 0]} maxWidth={16}>
          <Paragraph>{t("home.hero.copy")}</Paragraph>
        </Box>
        <Box padding={[1, 0]}>
          <Button>{t("home.hero.cta")}</Button>
        </Box>
      </Box>
    </>
  );
};

export default Home;
