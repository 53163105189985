import {Link} from "react-router-dom";
import styled from "styled-components";

/**
 * A styled react-router Link for navbar use.
 */
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.text.primary};
  font-size: 1.25rem;
  /* Montserrat semi-bold */
  font-weight: 600;
  transition: 100ms ease;
  box-shadow: inset 0px -0.2rem 0px transparent;

  &:hover {
    box-shadow: inset 0px -0.2rem 0px ${({ theme }) => theme.colors.accent};
  }
`;
