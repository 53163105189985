import styled from "styled-components";
import Box from "../Box";

/**
 * Props for the Flex component.
 */
export interface FlexProps {
  /**
   * The direction of the flexbox.
   */
  flexDirection?: "row" | "column";
  /**
   * How to align items on the opposite axis.
   */
  alignItems?: "flex-start" | "center" | "flex-end" | "space-between";
  /**
   * How to align items on the main axis.
   */
  justifyContent?: "flex-start" | "center" | "flex-end" | "space-between";
}

/**
 * A flexbox container built on top of the Box elements with
 * additional props for controlling the flex direction and justification.
 */
export const Flex = styled(Box)<FlexProps>`
  display: flex;

  ${({ flexDirection }) =>
    flexDirection ? `flex-direction: ${flexDirection};` : ``}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : ``)}
  ${({
    justifyContent,
  }) => (justifyContent ? `justify-content: ${justifyContent};` : ``)}
`;
