import styled from "styled-components";

/**
 * A button colored with the accent color and bold white
 * text.
 */
export const Button = styled.button`
  /* Reset */
  border: 0px;
  outline: 0;

  /* Button size and appearance */
  background-color: ${({ theme }) => theme.colors.accent};
  border: 1px solid transparent;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;

  /* Inner text */
  color: ${({ theme }) => theme.colors.buttonTextFill};
  font-size: 1rem;
  /* Montserrat semi-bold */
  font-weight: 600;

  transition: 100ms ease;

  &:hover {
    cursor: pointer;
    color: ${({theme}) => theme.colors.accent};
    background-color: transparent;
    border: 1px solid ${({theme}) => theme.colors.accent};
  }
`;
