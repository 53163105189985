import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en_US from "./strings/en_US.json";

i18n.use(initReactI18next).init({
  resources: {
    "en-US": en_US,
  },
  lng: "en-US",
  fallbackLng: "en-US",

  interpolation: {
    escapeValue: false,
  },
});
