import React from "react";
import { useTranslation } from "react-i18next";
import Box from "../../components/layout/Box";
import Grid from "../../components/layout/Grid";
import Header from "../../components/typography/Header";
import BlobsBackground from "../../features/BlobsBackground";
import CoinTile from "../../features/CoinTile";

const Launchpad = () => {
  const { t } = useTranslation();

  return (
    <>
      <BlobsBackground />
      <Box stretch padding={[16, 2]}>
        <Header>{t("launchpad.header")}</Header>
        <Grid padding={[2, 0]}>
          {/* Dummy data */}
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((id) => (
            <CoinTile
              key={id}
              title={`Coin ${id}`}
              price={"$23.49"}
              icoEnd={Date.now() + id * 38500000}
              amtRaised={"20.38 ETH"}
              targetAmt={"50.18 ETH"}
              coinImage={
                "https://bitbill.oss-accelerate.aliyuncs.com/pics/coins/bsc.svg"
              }
              percentRaised={50}
            />
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Launchpad;
