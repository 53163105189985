import styled from "styled-components";
import Card from "../../components/layout/Card";
import Header from "../../components/typography/Header";

/**
 * Wraps the CoinTile.
 */
export const Wrapper = styled(Card)`
  min-height: 160px;
`;

/**
 * The title of the coin.
 */
export const CoinTitle = styled(Header)`
  font-size: 1rem;
`;

/**
 * The price of the coin.
 */
export const CoinPrice = styled.span`
  /* Reset */
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.text.secondary};
  font-size: 0.8rem;
  /* Montserrat semi-bold */
  font-weight: 600;
`;

export const CoinImage = styled.div`
  width: 2rem;
  height: 2rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
