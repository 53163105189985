import React from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import Button from "../../components/input/Button";
import Box from "../../components/layout/Box";
import Flex from "../../components/layout/Flex";
import TableField from "../../components/layout/TableField";
import ProgressBar from "../../components/misc/ProgressBar";
import { CoinImage, CoinTitle, Wrapper } from "./CoinTile.styles";

/**
 * Props for the CoinTile component.
 */
export interface Props {
  title: string;
  price: string;
  icoEnd: number;
  amtRaised: string;
  targetAmt: string;
  coinImage: string;
  percentRaised: number;
}

/**
 * A tile showing an overview of a crypto coin.
 */
const CoinTile = ({
  title,
  price,
  icoEnd,
  amtRaised,
  targetAmt,
  coinImage,
  percentRaised,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Flex justifyContent="space-between" padding={[1, 1, 0, 1]}>
        <CoinTitle>{title}</CoinTitle>
        <CoinImage
          style={{
            backgroundImage: `url(${coinImage})`,
          }}
        />
      </Flex>
      <Box>
        <TableField title={t("coin.description.icoEnd")}>
          <Countdown
            date={icoEnd}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) return <>{"Completed!"}</>;

              return (
                <>
                  {days}d {hours}h {minutes}m {seconds}s
                </>
              );
            }}
          />
        </TableField>
      </Box>
      <Box>
        <TableField title={t("coin.description.amtRaised")}>
          {amtRaised} / {targetAmt}
        </TableField>
        <Box padding={[1, 0, 0, 0]}>
          <ProgressBar percentage={percentRaised} />
        </Box>
      </Box>
      <Flex stretch>
        <Button>{t("interaction.contribute")}</Button>
      </Flex>
    </Wrapper>
  );
};

export default CoinTile;
