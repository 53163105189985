import styled from "styled-components";
import Box from "../Box";

/**
 * Props for the Grid component.
 */
export interface GridProps {
  /**
   * The gap between each grid item (in rem).
   */
  gridGap?: number;
}

/**
 * A configurable generic CSS Grid element built on a Box with
 * sensible defaults.
 */
export const Grid = styled(Box)<GridProps>`
  width: 100%;
  display: grid;
  grid-gap: ${({ gridGap }) => (gridGap ? `${gridGap}rem` : `0.5rem`)};
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;
