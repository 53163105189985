import React from "react";
import {useTranslation} from "react-i18next";
import Box from "../../components/layout/Box";
import Header from "../../components/typography/Header";
import {Wrapper} from "./Navbar.styles";
import NavbarLink from "./NavbarLink";

/**
 * A sidebar with a logo and navbar menu.
 */
const Navbar = () => {
  const { t } = useTranslation();

  return (
    <Wrapper padding={[6, 4]}>
      <Header>Horizon</Header>
      <Box padding={[6, 0]}>
        <NavbarLink to="/">{t("navbar.links.home")}</NavbarLink>
        <NavbarLink to="/launchpad">{t("navbar.links.launchpad")}</NavbarLink>
        <NavbarLink to="/exchange">{t("navbar.links.exchange")}</NavbarLink>
        <NavbarLink to="/liquidity-locker">
          {t("navbar.links.liquidityLocker")}
        </NavbarLink>
      </Box>
    </Wrapper>
  );
};

export default Navbar;
