import styled from "styled-components";

/**
 * A medium-sized paragraph with light text.
 */
export const Paragraph = styled.p`
  /* Reset */
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.text.secondary};
  font-size: 1.1rem;
  /* Montserrat semi-bold */
  font-weight: 600;
  line-height: 1.5;
`;
