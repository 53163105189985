import { adjustHue } from "polished";
import styled from "styled-components";

/**
 * Wraps the background and positions it absolutely.
 */
export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 6vh;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
`;

/**
 * SVG element that fills whole page width,
 * minimal width of 1000px.
 */
export const Svg = styled.svg`
  width: 100%;
  min-width: 1000px;
`;

/**
 * A path element that uses the vibrant gradient.
 */
export const Path = styled.path`
  fill: url(#_gradient_vibrant);
`;

/**
 * The first stop of the linear gradient, just the accent color.
 */
export const StopOne = styled.stop`
  stop-color: ${({ theme }) => theme.colors.accent};
`;

/**
 * The second stop of the linear gradient, a hue-shifted version of
 * the accent color.
 */
export const StopTwo = styled.stop`
  stop-color: ${({ theme }) => adjustHue(-45, theme.colors.accent)};
`;
