import React from "react";
import Box from "../../../components/layout/Box";
import {StyledLink} from "./NavbarLink.styles";

/**
 * A styled react-router Link for use in the Navbar.
 */
const NavbarLink = (props: React.ComponentProps<typeof StyledLink>) => (
  <Box padding={[1, 0]}>
    <StyledLink {...props} />
  </Box>
);

export default NavbarLink;
