import styled from "styled-components";

/**
 * A large header.
 */
export const Header = styled.h1`
  /* Reset */
  margin: 0;
  padding: 0;

  color: ${({ theme }) => theme.text.primary};
  font-size: 2.5rem;
  /* Montserrat semi-bold */
  font-weight: 600;
`;
