import React from "react";
import { useTranslation } from "react-i18next";
import Box from "../../components/layout/Box";
import Header from "../../components/typography/Header";
import BlobsBackground from "../../features/BlobsBackground";

/**
 * Shown when a page is not found.
 */
const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <BlobsBackground />
      <Box padding={[16, 2]} maxWidth={32}>
        <Header>{t("error.pageNotFound")}</Header>
      </Box>
    </>
  );
};

export default NotFound;
