import React, { ReactNode } from "react";
import Box from "../../components/layout/Box";
import Flex from "../../components/layout/Flex";

import Navbar from "../Navbar";

/**
 * Props for the MainLayout component.
 */
export interface Props {
  children?: ReactNode;
}

/**
 * The MainLayout component wraps the site's pages with a sidebar containing
 * a navigation menu.
 */
const MainLayout = ({ children }: Props) => (
  <Flex padding={0} flexDirection="row">
    <Navbar />
    <Box stretch padding={0}>
      {children}
    </Box>
  </Flex>
);

export default MainLayout;
