const lightTheme = {
  colors: {
    background: "#FFFFFF",
    accent: "#fe860f",
    buttonTextFill: "#FFFFFF",
    borderColor: "#EEEEEE",
  },
  text: {
    primary: "#15181C",
    secondary: "#404349",
  },
};

export default lightTheme;
