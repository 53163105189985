import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from "fortmatic";
import MewConnect from "@myetherwallet/mewconnect-web-client";
import ReactDOM from "react-dom";
import Web3Connect from "web3connect";
import { TopRightCornerWrapper } from "./OverlayPortal.styles";

/**
 * Renders a web3 connect button over top of the page.
 */
const OverlayPortal = () => (
  <TopRightCornerWrapper padding={[1, 2]}>
    {
      // Just added a few random providers below, feel free
      // to replace as needed.
    }
    <Web3Connect.Button
      providerOptions={{
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: process.env.REACT_APP_INFURA_ID || "",
          },
        },
        mewconnect: {
          package: MewConnect, // required
          options: {
            infuraId: process.env.REACT_APP_INFURA_ID || "", // required
          },
        },
        fortmatic: {
          package: Fortmatic, // required
          options: {
            key: process.env.REACT_APP_FORTMATIC_KEY || "", // required
          },
        },
      }}
      onConnect={(provider: any) => {
        // Do something with the provider.
      }}
      onClose={() => {
        console.log("Web3Connect Modal Closed"); // modal has closed
      }}
    />
  </TopRightCornerWrapper>
);

const portal = () =>
  ReactDOM.createPortal(
    <OverlayPortal />,
    document.getElementById("overlay-root") as Element
  );

export default portal;
