import React from "react";
import {Path, StopOne, StopTwo, Svg, Wrapper} from "./BlobsBackground.styles";

/**
 * A colorful background made up of blobs colored
 * with the theme's accent color.
 */
const BlobsBackground = () => (
  <Wrapper>
    <Svg version="1.1" x="0px" y="0px" viewBox="0 0 1298 701.86">
      <g>
        <linearGradient id="_gradient_vibrant" gradientTransform="rotate(-45)">
          <StopOne offset="0" />
          <StopTwo offset="1" />
        </linearGradient>
        <Path d="M1298,4.01c0,0-32.64-1.88-57,69c-22,64-54,60-54,60c-37,0-105-51-186-51s-139,47-144,92c0,0-9,73.75,54,103c56,26,60.59,74.61,39,98c-36,39-10,92-10,92c17,32,72,77,176,77c96,0,122-65,182-65" />
        <Path d="M518,138.01c34.24,0,62-27.76,62-62s-32-71-120-76c-41.73-0.69-63,23-84,50c0,0-38.55,50.09-36,85c3,41,20,74,76,74c34.02,0,30.31-32.47,56-55C472,154.01,489,138.01,518,138.01z" />
        <Path d="M0,360.01c0,0,128-47,190,49c26,37,11,42,104,82c28,13,38,32.63,38,56c0,36-34,82-88,82c-41,0-42-26-75-26c-3,0-27.19,0.05-43,41c-16.53,42.8-82,57.84-126,57.84C0,702.01,0,360.01,0,360.01z" />
      </g>
    </Svg>
  </Wrapper>
);

export default BlobsBackground;
