import React from "react";
import { Wrapper, Inner } from "./ProgressBar.styles";

/**
 * Props for the ProgressBar component.
 */
export interface Props {
  percentage: number;
}

/**
 * Renders a progress bar.
 */
const ProgressBar = ({ percentage }: Props) => (
  <Wrapper>
    <Inner width={percentage} />
  </Wrapper>
);

export default ProgressBar;
