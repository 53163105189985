import styled from "styled-components";

/**
 * Wraps the progress bar and shows a "track".
 */
export const Wrapper = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.borderColor};
`;

/**
 * Props for the Inner component.
 */
export interface InnerProps {
  width: number;
}

/**
 * The inner filled portion of the progress bar.
 */
export const Inner = styled.div<InnerProps>`
  width: ${({ width }) => width}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 3px;
`;
