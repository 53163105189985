import styled from "styled-components";

/**
 * Props for a Box component.
 */
export interface BoxProps {
  /**
   * Padding can be set to either a single number for uniform padding,
   * a two-number tuple for [vertical, horizontal],
   * or a four-number tuple for [top, right, bottom, left].
   * All numbers are multiplied by 1rem.
   */
  padding?: number | [number, number] | [number, number, number, number];
  /**
   * Sets a maximum width (in rem).
   */
  maxWidth?: number;
  /**
   * Sets a maximum height (in rem).
   */
  maxHeight?: number;
  /**
   * Sets width to 100%.
   */
  stretch?: boolean;
}

/**
 * A generic box element for adding padding to elements.
 */
export const Box = styled.div<BoxProps>`
  box-sizing: border-box;

  ${({ stretch }) => (stretch ? `width: 100%;` : ``)}

  padding: 1rem;

  ${({ padding }) => {
    if (typeof padding == "number") return `padding: ${padding}rem;`;

    if (padding instanceof Array)
      return `padding: ${padding.map((val) => `${val}rem`).join(" ")};`;

    return ``;
  }}

  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}rem;` : ``)}

  ${({
    maxHeight,
  }) => (maxHeight ? `max-height: ${maxHeight}rem;` : ``)}
`;
