import React from "react";
import { Route, Switch } from "react-router-dom";
import MainLayout from "../../features/MainLayout";
import OverlayPortal from "../../features/OverlayPortal";
import Home from "../../pages/Home";
import Launchpad from "../../pages/Launchpad";
import NotFound from "../../pages/NotFound";

/**
 * The top-level router for the app.
 *
 * This does not include a BrowserRouter to allow for
 * nesting and modularity if needed.
 */
const Main = () => (
  <MainLayout>
    <OverlayPortal />
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/launchpad">
        <Launchpad />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  </MainLayout>
);

export default Main;
