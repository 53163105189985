import React, { ReactNode } from "react";
import Box from "../Box";
import { FieldTitle } from "./TableField.styles";

/**
 * Props for the TableField component.
 */
export interface Props {
  title: string;
  children?: ReactNode;
}

/**
 * Shows a field title and a field.
 */
const TableField = ({ title, children }: Props) => (
  <>
    <Box padding={[0, 0, 0.25, 0]}>
      <FieldTitle>{title}</FieldTitle>
    </Box>
    {children}
  </>
);

export default TableField;
