import styled from "styled-components";
import Box from "../Box";

/**
 * Props for the Card component.
 */
export interface CardProps {}

/**
 * A simple Card wrapper component, built on the Box component.
 */
export const Card = styled(Box)<CardProps>`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  transition: 100ms ease;

  &:hover {
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;
